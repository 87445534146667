export const documentationLinks = new Map([
  [
    'allocation-keys',
    'https://pwcdeu.sharepoint.com/sites/GBL-ADV-Gebuehren_Entgeltkalkulator/SitePages/Sekund%C3%A4rkosten.aspx',
  ],
  [
    'asset-cost-centers',
    'https://pwcdeu.sharepoint.com/sites/GBL-ADV-Gebuehren_Entgeltkalkulator/SitePages/Anlageverm%C3%B6gen.aspx',
  ],
  [
    'asset-forecast',
    'https://pwcdeu.sharepoint.com/sites/GBL-ADV-Gebuehren_Entgeltkalkulator/SitePages/Anlageverm%C3%B6gen%20fortschreiben.aspx',
  ],
  ['bab-modifications', undefined],
  [
    'corporate-risk',
    'https://pwcdeu.sharepoint.com/sites/GBL-ADV-Gebuehren_Entgeltkalkulator/SitePages/Allgemeines-Unternehmerwagnis.aspx',
  ],
  [
    'cost-centers',
    'https://pwcdeu.sharepoint.com/sites/GBL-ADV-Gebuehren_Entgeltkalkulator/SitePages/Kalkulatorische%20Kostenstellen.aspx',
  ],
  [
    'cost-type-accounts',
    'https://pwcdeu.sharepoint.com/sites/GBL-ADV-Gebuehren_Entgeltkalkulator/SitePages/Kostenarten.aspx',
  ],
  [
    'cost-units',
    'https://pwcdeu.sharepoint.com/sites/GBL-ADV-Gebuehren_Entgeltkalkulator/SitePages/Kostentr%C3%A4ger.aspx',
  ],
  [
    'cost-units-allocation',
    'https://pwcdeu.sharepoint.com/sites/GBL-ADV-Gebuehren_Entgeltkalkulator/SitePages/Kostentr%C3%A4ger%20Verteilung.aspx',
  ],
  ['custom-labels', undefined],
  [
    'expense-and-revenue-accounts',
    'https://pwcdeu.sharepoint.com/sites/GBL-ADV-Gebuehren_Entgeltkalkulator/SitePages/Konten.aspx',
  ],
  [
    'future-assets',
    'https://pwcdeu.sharepoint.com/sites/GBL-ADV-Gebuehren_Entgeltkalkulator/SitePages/Investitionsplan.aspx',
  ],
  [
    'imputed-concession-fee',
    'https://pwcdeu.sharepoint.com/sites/GBL-ADV-Gebuehren_Entgeltkalkulator/SitePages/Kalk.-Konzessionsabgabe.aspx',
  ],
  [
    'imputed-trade-tax',
    'https://pwcdeu.sharepoint.com/sites/GBL-ADV-Gebuehren_Entgeltkalkulator/SitePages/Kalk.-Gewerbesteuer.aspx',
  ],
  [
    'imputed-withdrawal-capital',
    'https://pwcdeu.sharepoint.com/sites/GBL-ADV-Gebuehren_Entgeltkalkulator/SitePages/Kalkulatorisches%20Abzugskapital.aspx',
  ],
  [
    'imputed-working-capital',
    'https://pwcdeu.sharepoint.com/sites/GBL-ADV-Gebuehren_Entgeltkalkulator/SitePages/Kalkulatorisches%20Umlaufverm%C3%B6gen.aspx',
  ],
  [
    'interest-rates',
    'https://pwcdeu.sharepoint.com/sites/GBL-ADV-Gebuehren_Entgeltkalkulator/SitePages/Zinss%C3%A4tze.aspx',
  ],
  ['manual-corrections-bab', undefined],
  ['manual-corrections-interest', undefined],
  [
    'planned-cost-revenue',
    'https://pwcdeu.sharepoint.com/sites/GBL-ADV-Gebuehren_Entgeltkalkulator/SitePages/Erfolgsplan.aspx',
  ],
  [
    'price-sheet',
    'https://pwcdeu.sharepoint.com/sites/GBL-ADV-Gebuehren_Entgeltkalkulator/SitePages/Geb%C3%BChren-%20oder%20Preisblatt.aspx',
  ],
  [
    'technical-data',
    'https://pwcdeu.sharepoint.com/sites/GBL-ADV-Gebuehren_Entgeltkalkulator/SitePages/Technische-Daten.aspx',
  ],
  [
    'withdraw-capital',
    'https://pwcdeu.sharepoint.com/sites/GBL-ADV-Gebuehren_Entgeltkalkulator/SitePages/Abzugskapital.aspx',
  ],
  [
    'bab-report',
    'https://pwcdeu.sharepoint.com/sites/GBL-ADV-Gebuehren_Entgeltkalkulator/SitePages/Betriebsabrechnungsbogen.aspx',
  ],
  [
    'cost-unit-report',
    'https://pwcdeu.sharepoint.com/sites/GBL-ADV-Gebuehren_Entgeltkalkulator/SitePages/Kostentr%C3%A4gerrechnung.aspx',
  ],
  [
    'interest-report',
    'https://pwcdeu.sharepoint.com/sites/GBL-ADV-Gebuehren_Entgeltkalkulator/SitePages/Zinstableau.aspx',
  ],
  [
    'price-sheet-report',
    'https://pwcdeu.sharepoint.com/sites/GBL-ADV-Gebuehren_Entgeltkalkulator/SitePages/Geb%C3%BChren-%20oder%20Preisblatt.aspx',
  ],
  ['trade-tax-report', undefined],
]);
