import { useCallback } from 'react';

import { skipToken } from '@tanstack/react-query';

import type { APIParams } from '../..';
import { queryKeyFn, useGetConfigsStatus } from '../dice';

export interface UseConfigStatusParams extends APIParams<'masterConfigurationId'> {}

export function useConfigStatus(pathParams: UseConfigStatusParams) {
  const { masterConfigurationId } = pathParams;

  const getConfigsStatusKey = useCallback(
    (configId = masterConfigurationId) =>
      queryKeyFn({
        operationId: 'getConfigsStatus',
        path: '/clients/{clientId}/master-configs/{masterConfigurationId}/status',
        variables: {
          pathParams: {
            ...pathParams,
            masterConfigurationId: configId,
          },
        },
      }),
    [pathParams, masterConfigurationId],
  );

  const { data: configsStatus } = useGetConfigsStatus(
    masterConfigurationId
      ? {
          pathParams,
        }
      : skipToken,
  );

  return { configsStatus, getConfigsStatusKey };
}
