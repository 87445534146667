import type { FC, PropsWithChildren } from 'react';
import { useEffect } from 'react';
import { usePathname, useRouter } from 'next/navigation';

import { useSession } from 'next-auth/react';

export const SessionGuard: FC<PropsWithChildren> = ({ children }) => {
  const session = useSession();
  const pathname = usePathname();
  const router = useRouter();

  useEffect(() => {
    if (session.status === 'unauthenticated' && !pathname.includes('/auth')) {
      const searchParams = new URLSearchParams({ callbackUrl: pathname });
      router.push(`/auth/signIn?${searchParams.toString()}`);
    }
  }, [pathname, router, session.status]);

  return children;
};
