'use client';

import type { FC, PropsWithChildren } from 'react';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

import { CheckIcon } from '@heroicons/react/24/outline';
import { twMerge } from 'tailwind-merge';

import type { WORKFLOW_STEP_STATUS } from '@org/utils';

interface WorkflowLinkProps {
  href: string;
  status?: keyof typeof WORKFLOW_STEP_STATUS;
  disabled?: boolean;
}

export const statusIcons = {
  CHANGED: (
    <div className="flex size-4 items-center justify-center rounded-full border-1 border-text">
      <CheckIcon className="w-2.5 text-text" />
    </div>
  ),
  ERROR: <div className="size-4 rounded-full border bg-error" />,
  SUCCESS: (
    <div className="flex size-4 items-center justify-center rounded-full bg-success-bright">
      <CheckIcon className="w-2.5 text-white" />
    </div>
  ),
  WARNING: <div className="size-4 rounded-full border bg-warning" />,
  EMPTY: <div className="size-4 rounded-full border border-text" />,
} as const;

export const WorkflowLink: FC<PropsWithChildren<WorkflowLinkProps>> = (props) => {
  const { children, href, status = 'EMPTY', disabled = false } = props;
  const pathname = usePathname();
  const selected = pathname === encodeURI(href);

  return (
    <Link
      aria-disabled={disabled}
      className={twMerge(
        'flex flex-row items-center gap-2 py-3.5 pl-5 text-sm hover:bg-gray-50',
        disabled && 'pointer-events-none bg-gray-50 text-gray-400',
        // FIXME: This should be theme variable
        selected && 'bg-[#d04a0234]',
      )}
      href={href}
    >
      {statusIcons[status]}
      {children}
    </Link>
  );
};
