'use client';

import { useMemo } from 'react';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

import { useTranslations } from 'next-intl';
import { twMerge } from 'tailwind-merge';

import { aggregated } from '@org/query';
import { Breadcrumbs } from '@org/ui';

import { usePathParams } from '~/hooks';
import {
  CLIENT_PAGE,
  CLIENT_PUBLIC_FACILITY_PAGE,
  CLIENT_PUBLIC_FACILITY_YEAR_PAGE,
  CONFIGURATION_PAGE,
  HOME_PAGE,
} from '~/urls';

export const ConfigurationBreadcrumbs = () => {
  const t = useTranslations();
  const pathParams = usePathParams();
  const { masterConfigurationId, clientId, publicFacilityId, yearId, type } = pathParams;
  const pathName = usePathname();
  const splitPathName = pathName.split('/');

  const { getClient } = aggregated.useClients();
  const client = useMemo(() => getClient(clientId), [clientId, getClient]);

  const { publicFacility } = aggregated.usePublicFacility(pathParams);

  const { masterConfiguration } = aggregated.useMasterConfiguration(pathParams);

  const { year } = aggregated.useYear(pathParams);

  const breadcrumbs = [
    <Link
      className={twMerge('text-orange-default', pathName === HOME_PAGE({}) && 'text-black')}
      href={HOME_PAGE({})}
      key="homePage"
    >
      {t('common.homePage')}
    </Link>,
  ];

  if (clientId) {
    breadcrumbs.push(
      <Link
        className={twMerge(
          'text-orange-default',
          pathName === CLIENT_PAGE({ clientId }) && 'text-black',
        )}
        href={CLIENT_PAGE({ clientId })}
        key={clientId}
      >
        {client?.clientName}
      </Link>,
    );
  }

  if (publicFacilityId) {
    breadcrumbs.push(
      <Link
        className={twMerge(
          'text-orange-default',
          pathName === CLIENT_PUBLIC_FACILITY_PAGE({ clientId, publicFacilityId }) && 'text-black',
        )}
        href={CLIENT_PUBLIC_FACILITY_PAGE({ clientId, publicFacilityId })}
        key={publicFacilityId}
      >
        {publicFacility?.name}
      </Link>,
    );
  }

  if (yearId) {
    breadcrumbs.push(
      <Link
        className={twMerge(
          'text-orange-default',
          pathName ===
            CLIENT_PUBLIC_FACILITY_YEAR_PAGE({ clientId, publicFacilityId, yearId, type }) &&
            'text-black',
        )}
        href={CLIENT_PUBLIC_FACILITY_YEAR_PAGE({ clientId, publicFacilityId, yearId, type })}
        key={yearId}
      >
        {t(`common.${type}CalculationShortcut`)} {year?.year}
      </Link>,
    );
  }

  if (masterConfigurationId) {
    breadcrumbs.push(
      <Link
        className={twMerge(
          'text-orange-default',
          pathName === CONFIGURATION_PAGE(pathParams) && 'text-black',
        )}
        href={CONFIGURATION_PAGE(pathParams)}
        key={masterConfigurationId}
      >
        {masterConfiguration?.name}
      </Link>,
    );
  }

  switch (splitPathName.at(-2)) {
    case 'report': {
      breadcrumbs.push(<div key="reports">{t('common.report')}</div>);
      break;
    }
    case 'configuration': {
      breadcrumbs.push(
        <div key="config">{t(`main.clients.menu.configMenu.${splitPathName.at(-1)}`)}</div>,
      );
      break;
    }
  }

  return <Breadcrumbs>{breadcrumbs}</Breadcrumbs>;
};
