import { useMemo } from 'react';

import { skipToken } from '@tanstack/react-query';

import type { APIParams } from '../..';
import type { YearCalculationDTO, YearDTOV2 } from '../dice';
import { useGetYears } from '../dice';

interface UseYearParams extends APIParams<'publicFacilityId' | 'yearId' | 'type'> {}

export const getGetYearById = (
  yearCalculationId: string,
  type: string,
  years: YearDTOV2[],
): [YearDTOV2, YearCalculationDTO] => {
  const yearPropName = `${type}YearCalculationDTO` as
    | 'preYearCalculationDTO'
    | 'postYearCalculationDTO';
  const yearByCalculationId = years.find(
    (yearItem) => yearItem[yearPropName]?.id === yearCalculationId,
  )!;
  return [yearByCalculationId, yearByCalculationId[yearPropName]!];
};

export const useYear = (pathParams: UseYearParams) => {
  const { publicFacilityId, yearId: yearCalculationId, type } = pathParams;

  const { data: years, isPending: yearsIsPending } = useGetYears(
    publicFacilityId
      ? {
          pathParams,
        }
      : skipToken,
  );

  const [singleYear, calculationYear] = useMemo(() => {
    if (type && yearCalculationId) {
      const yearPropName = `${type}YearCalculationDTO` as
        | 'preYearCalculationDTO'
        | 'postYearCalculationDTO';
      const yearByCalculationId = years?.find(
        (yearItem) => yearItem[yearPropName]?.id === yearCalculationId,
      );
      if (yearByCalculationId) {
        return [yearByCalculationId, yearByCalculationId[yearPropName]];
      }
    }

    return [undefined, undefined];
  }, [type, yearCalculationId, years]);

  return {
    calculationYear,
    isLoading: yearsIsPending,
    year: singleYear,
    years,
  };
};
