import type { QueryClientConfig } from '@tanstack/react-query';
import type { FC, PropsWithChildren } from 'react';

import { MutationCache, QueryClientProvider as RQProvider } from '@tanstack/react-query';
import { useTranslations } from 'next-intl';

import type { dice } from '../queries';
import { getQueryClient } from './getQueryClient';

export interface QueryProviderProps extends PropsWithChildren<{}> {
  queryClientConfig?: QueryClientConfig;
  showNotification: (params: {
    status: 'info' | 'error';
    message: string;
    duration?: false | number;
    detailedMessage?: { label: string; value: string }[];
    containerId?: string;
  }) => void;
}

export const QueryProvider: FC<QueryProviderProps> = (props) => {
  const { showNotification, ...rest } = props;
  const t = useTranslations();

  const {
    queryClientConfig = {
      mutationCache: new MutationCache({
        onSettled: () => {
          void queryClient.invalidateQueries();
        },
        onError: ({ errorType }: dice.EsraException) => {
          if (errorType === 'MASTER_CONFIGURATION_FINALIZED') {
            return showNotification({
              status: 'error',
              message: t('common.finalizedConfigurationWarningDescription'),
            });
          }
        },
      }),
    },
    ...queryProviderProps
  } = rest;

  const queryClient = getQueryClient(queryClientConfig);

  return (
    <RQProvider
      {...queryProviderProps}
      client={queryClient}
    />
  );
};
