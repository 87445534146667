import { useCallback, useMemo } from 'react';

import { useIsMutating } from '@tanstack/react-query';

import type { APIParams } from '../../types';
import type {
  PlannedAccountEntryDTO,
  PlannedCostRevenueDetailDTO,
  PlannedCostTypeAccountEntryDTO,
} from '../dice';
import {
  useCreatePlannedCostRevenueDirectEntry,
  useGetPlannedCostRevenueConfig,
  useGetPlannedCostRevenuePreselectedValues,
  useSavePlannedCostRevenueAccountEntry,
  useSavePlannedCostRevenueConfigDetails,
  useSavePlannedCostRevenueCostTypeAccountEntry,
} from '../dice';

interface UsePlannedCostRevenueProps extends APIParams<'masterConfigurationId'> {}

export interface PlannedCostRevenueEntry extends PlannedAccountEntryDTO {
  costTypeAccountName?: string;
  type?: string;
}

const plannedCostRevenueMutationKey = ['planned-cost-revenue-mutation'];

export const usePlannedCostRevenue = (pathParams: UsePlannedCostRevenueProps) => {
  const { data: plannedCostRevenue, isPending } = useGetPlannedCostRevenueConfig(
    {
      pathParams,
    },
    {
      select: (data) => ({
        plannedCostRevenueDetail: data.plannedCostRevenueDetail ?? {},
        plannedCostTypeAccountEntries: data.plannedCostTypeAccountEntries ?? [],
        plannedAccountEntries:
          data.plannedCostTypeAccountEntries
            ?.sort((entryA, entryB) => (entryA.rank ?? 0) - (entryB.rank ?? 0))
            .flatMap(
              (entry) =>
                entry.plannedAccountEntries?.flatMap<PlannedCostRevenueEntry>((accountEntry) => ({
                  ...accountEntry,
                  costTypeAccountName: entry.costTypeAccountName,
                  type: entry.type,
                })) ?? [],
            ) ?? [],
      }),
    },
  );

  const { data: preselectedValues } = useGetPlannedCostRevenuePreselectedValues({
    pathParams,
  });

  const preselectedTechnicalDataOptions = useMemo(
    () =>
      preselectedValues?.technicalDataGroups?.map(({ id, name }) => ({
        label: name ?? '',
        value: id ?? '',
      })) ?? [],
    [preselectedValues],
  );

  const isMutating =
    useIsMutating({
      exact: false,
      mutationKey: [plannedCostRevenueMutationKey],
    }) > 0;

  const updatePlannedCostRevenueDetailsMutation = useSavePlannedCostRevenueConfigDetails({
    mutationKey: [
      plannedCostRevenueMutationKey,
      pathParams,
      'PUT',
      'planned-cost-revenue',
      'details',
    ],
  });

  const { mutateAsync: updatePlannedCostRevenueCostTypeAccountEntryAsync } =
    useSavePlannedCostRevenueCostTypeAccountEntry({
      mutationKey: [
        plannedCostRevenueMutationKey,
        pathParams,
        'PUT',
        'planned-cost-revenue',
        '{plannedCostTypeAccountEntryId}',
      ],
    });

  const { mutateAsync: updatePlannedCostRevenueAccountEntryAsync } =
    useSavePlannedCostRevenueAccountEntry({
      mutationKey: [
        plannedCostRevenueMutationKey,
        pathParams,
        'PUT',
        'planned-cost-revenue',
        '{plannedAccountEntryId}',
        'account',
        '{plannedCostTypeAccountEntryId}',
      ],
    });

  const { mutateAsync: createPlannedCostRevenueDirectEntryAsync } =
    useCreatePlannedCostRevenueDirectEntry({
      mutationKey: [
        plannedCostRevenueMutationKey,
        pathParams,
        'POST',
        'planned-cost-revenue',
        '{plannedCostTypeAccountEntryId}',
        'account',
        '{plannedAccountEntryId}',
        'direct-entry',
        '{directEntryName}',
      ],
    });

  const updatePlannedCostRevenueCostTypeAccountEntry = useCallback(
    async (updatedPlannedCostTypeAccountEntry: PlannedCostTypeAccountEntryDTO) =>
      await updatePlannedCostRevenueCostTypeAccountEntryAsync({
        body: updatedPlannedCostTypeAccountEntry,
        pathParams: {
          ...pathParams,
          plannedCostTypeAccountEntryId: updatedPlannedCostTypeAccountEntry.id ?? '',
        },
      }),
    [pathParams, updatePlannedCostRevenueCostTypeAccountEntryAsync],
  );

  const updatePlannedCostRevenueAccountEntry = useCallback(
    async (updatedPlannedAccountEntry: PlannedAccountEntryDTO) =>
      await updatePlannedCostRevenueAccountEntryAsync({
        body: updatedPlannedAccountEntry,
        pathParams: {
          ...pathParams,
          plannedAccountEntryId: updatedPlannedAccountEntry.id ?? '',
          plannedCostTypeAccountEntryId: updatedPlannedAccountEntry.originalId ?? '',
        },
      }),
    [pathParams, updatePlannedCostRevenueAccountEntryAsync],
  );

  const createPlannedCostRevenueDirectEntry = useCallback(
    async ({
      plannedAccountEntryId,
      plannedCostTypeAccountEntryId,
      directEntryName,
    }: {
      plannedAccountEntryId: string;
      plannedCostTypeAccountEntryId: string;
      directEntryName: string;
    }) =>
      await createPlannedCostRevenueDirectEntryAsync({
        pathParams: {
          ...pathParams,
          directEntryName,
          plannedAccountEntryId,
          plannedCostTypeAccountEntryId,
        },
      }),
    [createPlannedCostRevenueDirectEntryAsync, pathParams],
  );

  const updatePlannedCostRevenueDetails = useCallback(
    (body: PlannedCostRevenueDetailDTO) =>
      updatePlannedCostRevenueDetailsMutation.mutateAsync({
        pathParams,
        body,
      }),
    [pathParams, updatePlannedCostRevenueDetailsMutation],
  );

  const getPlannedDirectEntry = useCallback(
    ({
      plannedAccountEntryId,
      plannedCostTypeAccountEntryId,
    }: {
      plannedAccountEntryId: string;
      plannedCostTypeAccountEntryId: string;
    }) => {
      const selectedPlannedAccountEntryNew = plannedCostRevenue?.plannedAccountEntries.find(
        (entry) => entry.id === plannedAccountEntryId,
      );

      return selectedPlannedAccountEntryNew?.plannedDirectEntries?.find(
        (entry) => entry.id === plannedCostTypeAccountEntryId,
      );
    },
    [plannedCostRevenue?.plannedAccountEntries],
  );

  return {
    plannedCostRevenueDetail: plannedCostRevenue?.plannedCostRevenueDetail,
    plannedAccountEntries: plannedCostRevenue?.plannedAccountEntries,
    isPending: isPending || updatePlannedCostRevenueDetailsMutation.isPending,
    plannedCostTypeAccountEntries: plannedCostRevenue?.plannedCostTypeAccountEntries,
    isMutating,
    updatePlannedCostRevenueDetails,
    updatePlannedCostRevenueAccountEntry,
    createPlannedCostRevenueDirectEntry,
    getPlannedDirectEntry,
    preselectedTechnicalDataOptions,
    updatePlannedCostRevenueCostTypeAccountEntry,
  };
};

export interface FlattenPlannedAccountEntries extends PlannedAccountEntryDTO {
  costTypeAccountName: string;
  type: string;
}
