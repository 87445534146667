'use client';

import Link from 'next/link';
import { usePathname, useRouter } from 'next/navigation';

import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { Tooltip } from '@heroui/react';
import { useTranslations } from 'next-intl';

import { usePermission } from '@org/features';
import { aggregated } from '@org/query';
import { Button, Container, showNotification } from '@org/ui';

import { documentationLinks } from '~/config/documentation';
import { usePathParams } from '~/hooks';
import { BAB_REPORT_PAGE } from '~/urls';

export function ConfigurationHeading() {
  const pathname = usePathname();
  const t = useTranslations();
  const pathParams = usePathParams();
  const { hasPermissionAndConfigIsActive } = usePermission(pathParams);
  const router = useRouter();
  const config = pathname.split('/').at(-1);

  const { masterConfigurationEntities } =
    aggregated.useMasterConfigurationByYearCalculation(pathParams);
  const currentMasterConfiguration = masterConfigurationEntities?.find(
    (item) => item.id === pathParams.masterConfigurationId,
  );

  const isFinalized = currentMasterConfiguration?.configActivityStatus === 'FINALIZED';
  const isBackup = currentMasterConfiguration?.type === 'BACKUP';

  const { masterConfiguration } = aggregated.useMasterConfiguration(pathParams);
  const { recalculateReport, isRecalculating } = aggregated.useRecalculateReport(pathParams);

  const { selectedEntity } = aggregated.useMasterConfigurationByYearCalculation({
    ...pathParams,
  });

  const documentationLink = documentationLinks.get(config ?? '');

  return (
    <Container behavior="fill">
      <div className="flex justify-between pb-2">
        <h4 className="flex items-center gap-2 text-lg font-medium">
          {isFinalized ? (
            <Tooltip
              content={t('common.finalizedConfiguration')}
              placement="bottom"
            >
              <CheckCircleIcon className="size-5 text-green-500" />
            </Tooltip>
          ) : null}{' '}
          {t(`main.clients.menu.configMenu.${config}`)}
          {documentationLink ? (
            <Button
              as={Link}
              className="w-6 rounded-full"
              href={documentationLink}
              target="_blank"
              variant="cellRenderer"
            >
              <InformationCircleIcon
                height={24}
                width={24}
              />
            </Button>
          ) : null}
        </h4>
        <div className="flex gap-4">
          {selectedEntity?.reportId ? (
            <Button
              as={Link}
              href={BAB_REPORT_PAGE(pathParams)}
            >
              {t('common.goToReport')}
            </Button>
          ) : null}

          {hasPermissionAndConfigIsActive ? (
            <Button
              isDisabled={isRecalculating || isFinalized || isBackup}
              isLoading={isRecalculating}
              onPress={async () => {
                try {
                  await recalculateReport({
                    masterConfiguration,
                  });
                  router.push(BAB_REPORT_PAGE(pathParams));
                } catch (error) {
                  const errMessage = (error as Error).message;
                  showNotification({ status: 'warning', message: errMessage });
                }
              }}
            >
              {t('common.recalculate')}
            </Button>
          ) : null}
        </div>
      </div>
      <hr />
    </Container>
  );
}
