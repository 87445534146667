import { useCallback } from 'react';

import { skipToken } from '@tanstack/react-query';

import type { APIParams } from '../../types';
import type { PublicFacilityDTO } from '../dice';
import {
  useDeletePublicFacility,
  useGetPublicFacility,
  usePostPublicFacility,
  usePutPublicFacility,
} from '../dice';

interface UsePublicFacilitiesProps extends APIParams {}

export const usePublicFacility = ({ clientId, publicFacilityId }: UsePublicFacilitiesProps) => {
  const { data: publicFacility, isPending: isPendingPublicFacility } = useGetPublicFacility(
    clientId && publicFacilityId
      ? {
          pathParams: {
            clientId,
            publicFacilityId,
          },
        }
      : skipToken,
  );

  const { mutateAsync: createPublicFacilityAsync } = usePostPublicFacility({});
  const { mutateAsync: updatePublicFacilityAsync } = usePutPublicFacility({});
  const { mutateAsync: deletePublicFacilityAsync } = useDeletePublicFacility({});

  const createPublicFacility = useCallback(
    async (body: PublicFacilityDTO) =>
      await createPublicFacilityAsync({
        body: {
          clientId: clientId as unknown as string, // TODO: BE needs to change types
          ...body,
        },
        pathParams: {
          clientId,
        },
      }),
    [clientId, createPublicFacilityAsync],
  );

  const updatePublicFacility = useCallback(
    async (body: PublicFacilityDTO) =>
      await updatePublicFacilityAsync({
        body: {
          clientId: clientId as unknown as string, // TODO: BE needs to change types
          ...body,
        },
        pathParams: {
          clientId,
        },
      }),
    [clientId, updatePublicFacilityAsync],
  );

  const deletePublicFacility = useCallback(
    async (id: string) =>
      await deletePublicFacilityAsync({
        pathParams: {
          clientId,
          publicFacilityId: id,
        },
      }),
    [clientId, deletePublicFacilityAsync],
  );

  return {
    createPublicFacility,
    deletePublicFacility,
    isLoading: isPendingPublicFacility,
    publicFacility,
    updatePublicFacility,
  };
};
