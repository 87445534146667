'use client';

import type { AbstractIntlMessages } from 'next-intl';
import type { FC, PropsWithChildren } from 'react';

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { HeroUIProvider } from '@heroui/react';
import { SessionProvider } from 'next-auth/react';
import { NextIntlClientProvider } from 'next-intl';
import { ToastContainer as ToastProvider } from 'react-toastify';

import { DesignProvider } from '@org/design';
import { QueryProvider } from '@org/query';
import { showNotification } from '@org/ui';

import { env } from '~/env.mjs';
import { SessionGuard } from './SessionGuard';

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  ServerSideRowModelModule,
  ClipboardModule,
  ExcelExportModule,
  MasterDetailModule,
  RowGroupingModule,
  MenuModule,
]);

LicenseManager.setLicenseKey(env.NEXT_PUBLIC_AG_GRID_LICENSE);

export const Providers: FC<
  PropsWithChildren<{
    messages: AbstractIntlMessages;
    locale?: string;
    timeZone?: string;
  }>
> = (props) => {
  const { children, messages, locale, timeZone } = props;

  return (
    <SessionProvider>
      <SessionGuard>
        <NextIntlClientProvider
          locale={locale}
          messages={messages}
          timeZone={timeZone}
        >
          <QueryProvider showNotification={showNotification}>
            <ToastProvider
              autoClose={5000}
              closeOnClick
              containerId="main-container"
              draggable
              hideProgressBar={false}
              newestOnTop={false}
              pauseOnFocusLoss={false}
              pauseOnHover
              position="top-left"
              rtl={false}
            />
            <DesignProvider>
              <HeroUIProvider>{children}</HeroUIProvider>
            </DesignProvider>
          </QueryProvider>
        </NextIntlClientProvider>
      </SessionGuard>
    </SessionProvider>
  );
};
