'use client';

import type { FC } from 'react';
import Link from 'next/link';
import { usePathname, useSearchParams } from 'next/navigation';

import { YearMenu as YearMenuFeature } from '@org/features';

import { usePathParams } from '~/hooks';

export const YearMenu: FC = () => {
  const params = usePathParams({});
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const { clientId, publicFacilityId, type, yearId } = params;

  return (
    <YearMenuFeature
      {...params}
      LinkComponent={Link}
      getConfigurationsLinkProps={() => ({
        href: `/client/${clientId}/${publicFacilityId}/${yearId}/${type}`,
        'data-selected': pathname === `/client/${clientId}/${publicFacilityId}/${yearId}/${type}`,
      })}
      getFileLinkProps={(fileType, hoverTarget) => {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set('fileType', fileType);
        newSearchParams.set('tab', hoverTarget ?? 'OVERVIEW');

        return {
          href: `/client/${clientId}/${publicFacilityId}/${yearId}/${type}/files?${newSearchParams.toString()}`,
          'data-selected': searchParams.get('fileType') === fileType,
        };
      }}
    />
  );
};

YearMenu.displayName = 'YearMenu';
