import * as reactQuery from '@tanstack/react-query';

import type { ProductIamContext } from './productIamContext';
import type * as Fetcher from './productIamFetcher';
import { productIamFetch } from './productIamFetcher';

export interface InviteHeaders {}

export type InviteError = Fetcher.ErrorWrapper<undefined>;

export type HealthcheckVariables = ProductIamContext['fetcherOptions'];

export interface InviteRequest {
  email: string;
}

export interface InviteResponse {
  description: string;
  status: 'SUCCESS' | (string & {});
}

export type InviteVariables = {
  body: InviteRequest;
  headers?: InviteHeaders;
} & ProductIamContext['fetcherOptions'];

export const fetchInvite = (variables: InviteVariables, signal?: AbortSignal) =>
  productIamFetch<InviteResponse, InviteError, InviteRequest, InviteHeaders, {}, {}>({
    url: '/invite',
    method: 'post',
    ...variables,
    signal,
  });

export const useInvite = (
  options?: Omit<
    reactQuery.UseMutationOptions<InviteResponse, InviteError, InviteVariables>,
    'mutationFn'
  >,
) =>
  reactQuery.useMutation<InviteResponse, InviteError, InviteVariables>({
    mutationFn: (variables: InviteVariables) => fetchInvite({ ...variables }),
    ...options,
  });

export interface RegisterHeaders {}

export type RegisterError = Fetcher.ErrorWrapper<undefined>;

export interface RegisterRequest {
  email: string;
  firstName: string;
  lastName: string;
  mobileNumber: string;
  password: string;
  registrationToken: string;
  preferredLanguage: string;
}

export interface RegisterResponse {
  description: string;
  status: 'SUCCESS' | (string & {});
}

export type RegisterVariables = {
  body: RegisterRequest;
  headers?: RegisterHeaders;
} & ProductIamContext['fetcherOptions'];

export const fetchRegister = (variables: RegisterVariables, signal?: AbortSignal) =>
  productIamFetch<RegisterResponse, RegisterError, RegisterRequest, RegisterHeaders, {}, {}>({
    url: '/register',
    method: 'post',
    ...variables,
    signal,
  });

export const useRegister = (
  options?: Omit<
    reactQuery.UseMutationOptions<RegisterResponse, RegisterError, RegisterVariables>,
    'mutationFn'
  >,
) =>
  reactQuery.useMutation<RegisterResponse, RegisterError, RegisterVariables>({
    mutationFn: (variables: RegisterVariables) => fetchRegister({ ...variables }),
    ...options,
  });

export interface QueryOperation {
  path: '/healthCheck';
  operationId: 'healthCheck';
  variables: HealthcheckVariables;
}
