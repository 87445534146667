import type { TabListState } from '@react-stately/tabs';
import type { AriaLabelingProps, Key, SingleSelection } from '@react-types/shared';
import type { ElementType, ReactNode } from 'react';
import type { VariantProps } from 'tailwind-variants';
import { useEffect, useMemo, useRef, useState } from 'react';

import { filterDOMProps } from '@react-aria/utils';
import { tv } from 'tailwind-variants';

import type { InternalComponentProps, OverrideProps } from '../../types';
import { createComponent } from '../../utils';
import { TabContext } from './TabContext';

const tabsVariants = tv({
  base: 'flex w-full flex-col',
  variants: {
    isDisabled: {
      true: '',
    },
    variant: {
      solid: '',
      underlined: '',
    },
  },
});

export interface TabsTypeMap<AdditionalProps = {}, DefaultComponent extends ElementType = 'div'> {
  props: AdditionalProps &
    AriaLabelingProps &
    SingleSelection &
    VariantProps<typeof tabsVariants> & {
      /**
       * Whether tabs are activated automatically on focus or manually.
       * @default 'automatic'
       */
      keyboardActivation?: 'automatic' | 'manual';
      /** The children of the `<Tabs>` element. Should include `<TabList>` and `<TabPanels>` elements. */
      children: ReactNode;
      /** The item objects for each tab, for dynamic collections. */
      items?: Iterable<object>;
      /** The keys of the tabs that are disabled. These tabs cannot be selected, focused, or otherwise interacted with. */
      disabledKeys?: Iterable<Key>;
      /** Whether the Tabs are disabled. */
      isDisabled?: boolean;
    };
  defaultComponent: DefaultComponent;
}
export type TabsProps<
  RootComponent extends ElementType = TabsTypeMap['defaultComponent'],
  AdditionalProps = {},
> = OverrideProps<TabsTypeMap<AdditionalProps, RootComponent>, RootComponent>;

type InternalTabsProps<AdditionalProps = {}> = InternalComponentProps<
  TabsTypeMap<AdditionalProps, TabsTypeMap['defaultComponent']>
>;

export const Tabs = createComponent<TabsTypeMap>((inProps: InternalTabsProps) => {
  const {
    as: Component = 'div',
    children,
    className,
    isDisabled,
    variant = 'underlined',
    ref,
    ...otherProps
  } = inProps;

  const domRef = useRef(ref);
  const tablistRef = useRef<HTMLDivElement>(null);

  const [selectedTab, setSelectedTab] = useState<Element>();
  const [tabListState, setTabListState] = useState<TabListState<object> | null>(null);

  useEffect(() => {
    if (tablistRef.current && selectedTab != null) {
      setSelectedTab(
        tablistRef.current.querySelector(
          `[data-key="${CSS.escape(tabListState?.selectedKey?.toString() ?? '')}"]`,
        )!,
      );
    }
    // collapse is in the dep array so selectedTab can be updated for TabLine positioning
  }, [children, selectedTab, tabListState?.selectedKey, tablistRef]);

  const styles = useMemo(
    () => tabsVariants({ className, isDisabled, variant }),
    [className, isDisabled, variant],
  );

  return (
    <TabContext.Provider
      value={{
        refs: { tablistRef },
        tabProps: {
          ...inProps,
          variant,
        },
        tabState: { selectedTab, setTabListState, tabListState },
      }}
    >
      <Component
        {...filterDOMProps(otherProps)}
        className={styles}
        ref={domRef}
      >
        {children}
      </Component>
    </TabContext.Provider>
  );
});

Tabs.displayName = 'Tabs';
