'use client';

import { useRouter } from 'next/navigation';

import { useTranslations } from 'next-intl';

import { Button, Container } from '@org/ui';

import { ConfigurationBreadcrumbs, Header } from '~/components';

export function NotFoundPage() {
  const t = useTranslations();

  const router = useRouter();

  return (
    <div className="flex min-h-screen flex-col">
      <Header className="z-30">
        <div className="flex h-8 items-center bg-grey-lighter">
          <Container behavior="fill">
            <ConfigurationBreadcrumbs />
          </Container>
        </div>
      </Header>
      <div className="my-12 flex min-h-screen flex-col items-center gap-y-4">
        <h2 className="text-3xl">{t('common.notFound.title')}</h2>
        <p>{t('common.notFound.description')}</p>
        <Button
          className="mb-2 ml-3"
          onPress={() => router.back()}
          size="sm"
          variant="primary"
        >
          {t('common.notFound.goBack')}
        </Button>
      </div>
    </div>
  );
}
